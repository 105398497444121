import React, { useEffect, useState, useContext } from "react";
import { FormControl, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import * as Yup from 'yup';
import * as auth from '../Auth/_redux/authRedux';
import { Formik } from "formik";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ROLES } from '../../services/flitEnum';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FlitServiceContext } from "../../services/flitService";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import { LinearProgress } from "@material-ui/core";
import { useLang } from "../../../_metronic/i18n";
import cf from "../../services/codeformatter";

function UserDialog(props){
  const { show, onHide, userId}= props;
  const intl = useIntl();
  const lang = useLang();
    const { flitSvc } = useContext(FlitServiceContext);
    //const { setFieldValue } = useFormikContext() || {}; // nullsafe deconstructing, setFieldValue from enclosing component
    useEffect(() => {
        if (show) {
          setBusinessEntityOptions([]);
          setBusinessEntitySearchCount(0);
          setBusinessEntitySearchString("");
          setIsChangingBusinessEntity(false);
          setInitialValues(initialEntry);
          if(userId){
              setIsLoading(true);
              flitSvc.getUser(userId).then(({data}) => {

                  _handleBusinessEntitySearch(data.businessEntity ? data.businessEntity.legalName : "");
                  if(data.encodedImage) setImagePreview(data.encodedImage);
                  data.userRoles_select = data.userRole.map(s => ({ value:s.role, label:cf.format("Roles", s.role, intl.locale)}));
                  //data.isNotificationBlocked = data.userConfig?.isNotificationBlocked;
                  if (!data.userConfig) {
                    data.userConfig = {lang:lang, isNotificationBlocked:false, userId: data.id}
                  } 
                  setInitialValues(data);
              }).finally(() => {
                  setIsLoading(false);
              });
          }
      }
    }, [show]);

    const initialEntry = {
        id: "",
        email:"",
        firstName: "",
        lastName:"",
        phoneNo:"",
        userRoles_select: [],
        registrationChannel:"",
        businessEntityId: "",
        encodedImage: "",
        userConfig: { isNotificationBlocked:false, lang:lang}
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "WEBP",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
    });

    const { isOperator } = useSelector(
      ({ auth }) => ({
          isOperator: auth.user?.userRole?.some(r => [ROLES.OPERATOR, ROLES.MANAGER, ROLES.OPERATOR].includes(r.role)),
      })
  );

    const [initialValues, setInitialValues] = React.useState(initialEntry);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isChangingBusinessEntity, setIsChangingBusinessEntity] = React.useState(false);
    const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
    const [businessEntitySearchCount, setBusinessEntitySearchCount] = React.useState(0);
    const [businessEntitySearchString, setBusinessEntitySearchString] = React.useState("");
    const [imagePreview, setImagePreview] = useState("");
    const fileInputRef = React.useRef();
    const PER_PAGE = 10;
    const UserScheme = Yup.object().shape({
        email: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        firstName: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        lastName: Yup.string().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
        userRoles_select: Yup.array().nullable().required(intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })).min(1, intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })),
        businessEntityId : Yup.number().required(
            intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    });

    function _handleBusinessEntitySearch(businessEntityName){
        //setIsLoading(true);
        setBusinessEntitySearchString(businessEntityName);
        flitSvc.searchBusinessEntityIdByName(businessEntityName, PER_PAGE, 0)
            .then(({ data }) => {
                setBusinessEntityOptions(data.value);
                setBusinessEntitySearchCount(data['@odata.count']);
                //setIsLoading(false);

            });
    }

    function _filterCallback(option, props) {
        return true;
    }

    function _handleBusinessEntityPagination(e, shownResults) {
        if (
            businessEntityOptions.length > shownResults ||
            businessEntityOptions.length === businessEntitySearchCount
        ) {
            return;
        }

        flitSvc.searchBusinessEntityIdByName(businessEntitySearchString, shownResults - businessEntityOptions.length + 1, businessEntityOptions.length)
            .then(({ data }) => {
                setBusinessEntityOptions(businessEntityOptions.concat(data.value));
                setBusinessEntitySearchCount(data['@odata.count']);
                setIsLoading(false);

            });
    }

    async function handleFileChange(e) {
        setIsLoading(true);
        e.preventDefault();
        let file = e.target.files[0];
        var thumbnail = await resizeFile(file);
        if(thumbnail)setImagePreview([thumbnail]);
        setIsLoading(false);
        return true;
      }
    
      function removeImageFile(){
        setImagePreview(["DELETED"]);
        fileInputRef.current.value = "";
      }

    return (
      <>
        <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {intl.formatMessage({ id: "EDIT_USER" })}
            </Modal.Title>
            
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            validationSchema={UserScheme}
            initialValues={initialValues}
            validateOnChange={true}
            onSubmit={ (values, { setStatus, setSubmitting }) => {
              setSubmitting(true);
              values.userRole = values.userRoles_select
                ? values.userRoles_select.map((el) => ({
                    Role: el.value,
                    UserId: values.id,
                  }))
                : [];
              //</Modal>values.userConfig.isNotificationBlocked = values.isNotificationBlocked 
              //? values.isNotificationBlocked : false;
                if (imagePreview && values.encodedImage != imagePreview && imagePreview != "DELETED") values.encodedImage = imagePreview[0];
                else if (imagePreview == "DELETED") {
                    values.encodedImage = "";
                }
              if (values.id) {
                flitSvc
                  .updateUser(values)
                  .then(({ data }) => {
                    props.requestUser();
                    onHide(true);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              validateField,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <>
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                  className="form form-label-right"
                >
                  {isLoading && <LinearProgress />}
                  <Modal.Body>
                  
                    <Row>
                        <Col>
                          <Form.Group controlId="imageUrl">
                            <Form.Label>
                              {intl.formatMessage({ id: "PROFILE_PICTURE" })}
                            </Form.Label>
                            <div className="d-flex align-items-center flex-wrap">
                                <div
                                  className="symbol symbol-120 mr-5 image-input image-input-outline"
                                  data-kt-image-input="true"
                                >
                                  {/* {layoutProps.offcanvas && (<UserProfileDropdown/>)} */}
                                  {imagePreview && imagePreview != "DELETED" ? (
                                    <div className="image-input image-input-outline">
                                      <div
                                        className="symbol-label image-input-wrapper"
                                        style={{
                                          backgroundImage: `url(${imagePreview})`,
                                        }}
                                      />
                                      <span className="symbol-badge badge badge-circle bg-danger start-100"></span>
                                    </div>
                                  ) : (
                                    <div>
                                      <span
                                        className="symbol-label font-size-h5 font-weight-bold image-input image-input-outline"
                                        data-kt-image-input="true"
                                      >
                                        {(values.firstName
                                          ? values.firstName[0]
                                          : "") +
                                          (values.lastName
                                            ? values.lastName[0]
                                            : "")}
                                      </span>
                                      <span className="symbol-badge badge badge-circle bg-danger start-100"></span>
                                    </div>
                                  )}
                                  {/*<label 
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" 
                                    data-action="change" 
                                    data-toggle="tooltip" 
                                    title="" 
                                    data-original-title="Change avatar"
                                    disabled={isSubmitting || isLoading}
                                    
                                  >
                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                    <input 
                                      type="file"  
                                      onChange={handleFileChange}
                                      ref={fileInputRef}
                                      name="profile_avatar" 
                                      accept=".png, .jpg, .jpeg, .webp"
                                    />
                                          </label>*/}
                                  <span 
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" 
                                    data-action="remove" 
                                    data-toggle="tooltip" 
                                    title="Remove avatar"
                                    disabled={isSubmitting || isLoading}
                                    hidden={!imagePreview || imagePreview == "DELETED"}
                                  >
                                    <i
                                      className="ki ki-bold-close icon-xs text-muted" 
                                      onClick={removeImageFile}
                                    ></i>
                                  </span>
                                </div>
                            </div>
                          </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="firstName">
                          <Form.Label>
                            {intl.formatMessage({ id: "USER_FIRST_NAME" })}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            disabled={isSubmitting || isLoading}
                            className={
                              touched.firstName && errors.firstName
                                ? "is-invalid"
                                : null
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div className="fv-plugins-message-container">
                            {errors.firstName && touched.firstName ? (
                              <div className="fv-help-block">
                                {errors.firstName}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="lastName">
                          <Form.Label>
                            {intl.formatMessage({ id: "USER_LAST_NAME" })}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            disabled={isSubmitting || isLoading}
                            className={
                              touched.lastName && errors.lastName
                                ? "is-invalid"
                                : null
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div className="fv-plugins-message-container">
                            {errors.lastName && touched.lastName ? (
                              <div className="fv-help-block">
                                {errors.lastName}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="email">
                          <Form.Label>
                            {intl.formatMessage({ id: "USER_EMAIL" })}
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            disabled={true}
                            className={
                              touched.email && errors.email
                                ? "is-invalid"
                                : null
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div className="fv-plugins-message-container">
                            {errors.email && touched.email ? (
                              <div className="fv-help-block">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        {!isChangingBusinessEntity && (
                          <Form.Group controlId="businessEntity">
                            <Form.Label>
                              {intl.formatMessage({ id: "BUSINESS_ENTITY" })}
                            </Form.Label>
                            <InputGroup>
                              <FormControl
                                     type="text"
                                     name="legalName"
                                     value={values.businessEntity?.legalName || ''}
                                     disabled
                                     onChange={() => {}}
                              />
                              <Button
                                variant="outline-secondary"
                                disabled = {!isOperator}
                                onClick={() => {
                                  setFieldValue("businessEntityId", "");
                                  setIsChangingBusinessEntity(true);
                                  _handleBusinessEntitySearch("");
                                }}
                              >
                                {intl.formatMessage({ id: "CHANGE" })}
                              </Button>
                            </InputGroup>
                            <div className="fv-plugins-message-container">
                              {errors.businessEntityId ? (
                                <div className="fv-help-block">
                                  {errors.businessEntityId}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        )}
                        {isChangingBusinessEntity && (
                          <Form.Group controlId="businessEntityId">
                            <Form.Label>
                              {intl.formatMessage({ id: "BUSINESS_ENTITY" })}
                            </Form.Label>
                            <Row>
                              <Col>
                                <AsyncTypeahead
                                  id="businessEntityId"
                                  onChange={(selected) => {
                                    const value =
                                      selected.length > 0 ? selected[0].id : "";
                                    setFieldValue("businessEntityId", value);
                                  }}
                                  onInputChange={(text, event) => {
                                    if (!text) _handleBusinessEntitySearch("");
                                  }}
                                  onBlur={(e) =>
                                    setFieldTouched("businessEntityId", true)
                                  }
                                  paginate
                                  maxResults={PER_PAGE - 1}
                                  isLoading={isLoading}
                                  multiple={false}
                                  allowNew={false}
                                  isDisabled={!isOperator}
                                  options={businessEntityOptions}
                                  labelKey={(option) => `${option.legalName}`}
                                  minLength={0}
                                  onSearch={_handleBusinessEntitySearch}
                                  filterBy={_filterCallback}
                                  onPaginate={_handleBusinessEntityPagination}
                                  selected={businessEntityOptions.filter(
                                    (el) => el.id == values.businessEntityId
                                  )}
                                  placeholder={intl.formatMessage({
                                    id: "SEARCH_BUSINESS_ENTITY",
                                  })}
                                  renderMenuItemChildren={(option, props) => (
                                    <div key={option.id}>
                                      {option.legalName}
                                    </div>
                                  )}
                                  paginationText={intl.formatMessage({
                                    id: "SHOW_MORE_RESULTS",
                                  })}
                                  useCache={false}
                                />
                              </Col>
                            </Row>
                            <div className="fv-plugins-message-container">
                              {errors.businessEntityId &&
                              touched.businessEntityId ? (
                                <div className="fv-help-block">
                                  {errors.businessEntityId}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="phoneNo">
                          <Form.Label>
                            {intl.formatMessage({ id: "USER_PHONENO" })}
                          </Form.Label>
                          <Form.Control
                            type="phone"
                            name="phoneNo"
                            value={values.phoneNo}
                            disabled={isSubmitting || isLoading}
                            className={
                              touched.phoneNo && errors.phoneNo
                                ? "is-invalid"
                                : null
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <div className="fv-plugins-message-container">
                            {errors.phoneNo && touched.phoneNo ? (
                              <div className="fv-help-block">
                                {errors.phoneNo}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                      <Form.Group controlId="isNotificationBlocked">
                              <Form.Label>
                                {intl.formatMessage({ id: "USER_IS_BLOCK_NOTIFICATION" })}
                              </Form.Label>
                              <Form.Check 
                              type="checkbox" 
                              name="isNotificationBlocked" 
                              id="isNotificationBlocked"
                              onBlur={handleBlur}
                              checked={values.userConfig?.isNotificationBlocked}
                              onChange={(e) => {
                                setFieldValue('userConfig.isNotificationBlocked', !values.userConfig?.isNotificationBlocked)
                              }}
                              >
                              </Form.Check>
                      </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="userRoles">
                          <Form.Label>
                            {intl.formatMessage({ id: "ROLES" })}
                          </Form.Label>
                          <Select
                            isMulti
                            options={Object.values(ROLES).map((role) => (
                              {
                                value: role,
                                label: cf.format("Roles", role, intl.locale),
                              }
                            ))}
                              
                            isDisabled={isSubmitting || isLoading || !isOperator}
                            value={values.userRoles_select}
                            onChange={(s) => {
                              setFieldValue("userRoles_select", s);
                            }}
                          />
                          <div className="fv-plugins-message-container">
                            {errors.userRoles_select &&
                            touched.userRoles_select ? (
                              <div className="fv-help-block">
                                {errors.userRoles_select}
                              </div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    {Object.keys(errors).length !== 0 ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {intl.formatMessage({ id: "FORM_ERROR" })}
                        </div>
                      </div>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => onHide()}
                      disabled={isSubmitting}
                      className="btn btn-light btn-elevate"
                    >
                      {intl.formatMessage({ id: "CANCEL_BUTTON" })}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting || isLoading}
                      className={`btn btn-primary btn-elevate float-right kt-login__btn-primary`}
                    >
                      {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                      {isSubmitting && (
                        <span className="ml-3 mr-5 spinner spinner-white"></span>
                      )}
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            )}
          </Formik>
        </Modal>
      </>
    );

}

export default (connect(null,auth.actions)(UserDialog));