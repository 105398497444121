import React, { useContext, useState, useEffect } from "react";
import Moment from "react-moment";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { FlitServiceContext } from "../../services/flitService";
import userImage from "../../assets/images/users.png";
import  UserDialog  from "./UserDialog";
import {
  makeStyles,
  lighten,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  Card,
  CardHeaderToolbar,
  CardBody,
  CardHeader,
} from "./../../../_metronic/_partials/controls/Card";
import { useSubheader } from "./../../../_metronic/layout/_core/MetronicSubheader";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TableSortLabel,
  TablePagination,
  Switch,
  FormControlLabel,
  LinearProgress,
  TableFooter,
} from "@material-ui/core";
import {
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  getUserIsActiveCssClasses,
  getUserRoleCssClasses,
} from "./UserUIHelper";
import { ROLES } from "./../../services/flitEnum";
import cf from "./../../services/codeformatter";
import { useRef } from "react";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 13,
  },
}))(TableCell);

const headRows = [
  {
    id: "firstname",
    align: "left",
    disablePadding: false,
    label: "FIRSTNAME",
    sortable: true,
  },
  {
    id: "lastname",
    align: "left",
    disablePadding: false,
    label: "LASTNAME",
    sortable: true,
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "EMAIL",
    sortable: true,
  },
  {
    id: "status",
    align: "center",
    disablePadding: true,
    label: "STATUS",
    sortable: false,
  },
  {
    id: "userRoles",
    align: "center",
    disablePadding: true,
    label: "ROLES",
    sortable: false,
  },
  {
    id: "businessEntities",
    align: "center",
    disablePadding: true,
    label: "BUSINESSENTITY",
    sortable: false,
  },
  {
    id: "registrationChannel",
    align: "center",
    disablePadding: true,
    label: "REGISTRATION_CHANNEL",
    sortable: false,
  },
  {
    id: "createdOn",
    align: "center",
    disablePadding: true,
    label: "REGISTRATION_DATE",
    sortable: true,
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

function UserTableHead(props) {
  const intl = useIntl();
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {intl.formatMessage({ id: row.label })}
              </TableSortLabel>
            ) : (
              intl.formatMessage({ id: row.label })
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
UserTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
function UserList(props) {
  const subheader = useSubheader();
  const { intl, history } = props;
  const { flitSvc } = useContext(FlitServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [currentUserId, setCurrentUserId] = useState();
  const [showUserDialog, setShowUserDialog] = useState(false);
  const [dense, setDense] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const businessEntityRef = useRef(null);
  const [businessEntityOptions, setBusinessEntityOptions] = React.useState([]);
  const [
    businessEntitySearchCount,
    setBusinessEntitySearchCount,
  ] = React.useState(0);
  const [
    businessEntitySearchString,
    setBusinessEntitySearchString,
  ] = React.useState("");
  const PER_PAGE = 5;

  const useQuery = () => new URLSearchParams(useLocation().search);
  let qPage = useQuery().get("page");
  let qRowsPerPage = useQuery().get("rowsPerPage");

  const [filter, setFilter] = useState({
    page: qPage ? +qPage : 0,
    rowsPerPage: qRowsPerPage ? +qRowsPerPage : 5,
    orderBy: useQuery().get("orderBy") ?? "createdOn",
    order: useQuery().get("order") ?? "desc",
    isActive: useQuery().get("isActive") ?? "active",
    role: useQuery().get("role") ?? ROLES.CUSTOMER,
    businessEntity: useQuery().get("businessEntity") ?? "",
    nameOrEmail: useQuery().get("nameOrEmail") ?? "",
  });
  subheader.setTitle(intl.formatMessage({ id: "MENU.USER" }));
  const classes = useStyles();

  function refreshList() {
    setIsLoading(true);
    var userFilters = [
      {
        field: "isActive",
        operator: "eq",
        value:
          filter.isActive == "active"
            ? true
            : filter.isActive == "inactive"
            ? false
            : "",
      },
    ];
    if (filter.businessEntity) {
      userFilters.push({
        field: "businessEntityId",
        operator: "eq",
        value: Number(filter.businessEntity),
      });
      if (!businessEntitySearchString) {
        flitSvc.getBusinessEntity(filter.businessEntity).then(({ data }) => {
          _handleSearch(data.legalName);
          if (businessEntityRef.current) businessEntityRef.current.setState({text: data.legalName});
      });
      }
    } else {
      flitSvc.searchBusinessEntityIdByName("", PER_PAGE, 0).then(({ data }) => {
        setBusinessEntityOptions(data.value);
        setBusinessEntitySearchCount(data["@odata.count"]);
      });
    }
    if (filter.role) {
      userFilters.push({
        raw: "userRole/any(r:r/role eq '" + filter.role + "')",
      });
    }
    if (filter.nameOrEmail) {
      userFilters.push({
        raw:
          "(contains(firstname,'" +
          filter.nameOrEmail +
          "') or contains(lastname,'" +
          filter.nameOrEmail +
          "') or contains(email,'" +
          filter.nameOrEmail +
          "'))",
      });
    }
    
    flitSvc
      .searchUser(
        filter.rowsPerPage,
        filter.page * filter.rowsPerPage,
        filter.orderBy,
        filter.order,
        flitSvc.makeFilterString(userFilters)
      )
      .then(({ data }) => {
        setUserList(
          data.value?.map((item) => {
            item.status = item.isActive ? "Active" : "Inactive";
            return item;
          })
        );
        setTotalRows(data["@odata.count"]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    flitSvc.insertUrlParam(filter);
    refreshList();
  }, [filter]);

  function openUserDialog(userId){
    if(userId){
      setCurrentUserId(userId);
    } else {
      setCurrentUserId("");
    }
    setShowUserDialog(true);
  }

  function closeUserDialog(isUpdated){
    if(isUpdated) refreshList();
    setShowUserDialog(false);
  }

  //Table functions
  function handleRequestSort(event, property) {
    const isDesc = filter.orderBy === property && filter.order === "desc";
    filter.order = isDesc ? "asc" : "desc";
    filter.orderBy = property;
    setFilter({ ...filter });
  }

  function handleChangePage(event, newPage) {
    filter.page = newPage;
    setFilter({ ...filter });
  }

  function handleChangeRowsPerPage(event) {
    filter.rowsPerPage = +event.target.value;
    filter.page = 0;
    setFilter({ ...filter });
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function _handleSearch(name) {
    setBusinessEntitySearchString(name);
    flitSvc.searchBusinessEntityIdByName(name, PER_PAGE, 0).then(({ data }) => {
      setBusinessEntityOptions(data.value);
      setBusinessEntitySearchCount(data["@odata.count"] + 1);
    });
  }

  function _filterCallback(option, props) {
    return true;
  }

  function _handlePagination(e, shownResults) {
    if (
      businessEntityOptions.length > shownResults ||
      businessEntityOptions.length === businessEntitySearchCount
    ) {
      return;
    }

    flitSvc
      .searchBusinessEntityIdByName(
        businessEntitySearchString,
        shownResults - businessEntityOptions.length + 1,
        businessEntityOptions.length
      )
      .then(({ data }) => {
        setBusinessEntityOptions(businessEntityOptions.concat(data.value));
        setBusinessEntitySearchCount(data["@odata.count"]);
        setIsLoading(false);
      });
  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "USER_LIST" })} />
        <CardBody>
          <Row>
            <Col className="d-flex align-items-center flex-wrap">
              <Form.Group controlId="userStatus" className="mr-7">
                <Form.Control
                  as="select"
                  style={{ width: "200px" }}
                  defaultValue={filter.isActive}
                  onChange={(e) => {
                    filter.page = 0;
                    filter.isActive = e.target.value;
                    setFilter({ ...filter });
                  }}
                >
                  <option value="">
                    {intl.formatMessage({ id: "ALL_USERS" })}
                  </option>
                  <option value="active">
                    {intl.formatMessage({ id: "ACTIVE_USERS" })}
                  </option>
                  <option value="inactive">
                    {intl.formatMessage({ id: "INACTIVE_USERS" })}
                  </option>
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_STATUS" })}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="userRole" className="mr-7">
                <Form.Control
                  as="select"
                  style={{ width: "200px" }}
                  defaultValue={filter.role}
                  onChange={(e) => {
                    filter.page = 0;
                    filter.role = e.target.value;
                    setFilter({ ...filter });
                  }}
                >
                  <option value="">
                    {intl.formatMessage({ id: "ALL_USERS" })}
                  </option>
                  {Object.values(ROLES).map((role) => (
                    <option key={role} value={role}>
                      {cf.format("Roles", role, intl.locale)}
                    </option>
                  ))}
                </Form.Control>
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_ROLE" })}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="userNameOrEmail" className="mr-7">
                <Form.Control
                  type="text"
                  style={{ width: "200px" }}
                  defaultValue={filter.nameOrEmail}
                  onChange={(e) => {
                    if (
                      e.target.value.length >= 2 ||
                      e.target.value.length == 0
                    ) {
                      filter.page = 0;
                      filter.nameOrEmail = e.target.value;
                      setFilter({ ...filter });
                    }
                  }}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "BY_NAME_OR_EMAIL" })}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="businessEntity" className="mr-7">
                <AsyncTypeahead
                  id="businessEntity"
                  onChange={(selected) => {
                    const value = selected.length > 0 ? selected[0].id : '';
                    filter.businessEntity = value;
                    filter.page = 0;
                    setFilter({ ...filter });
                  }}
                  onInputChange={(text, event) => {
                    if (!text) _handleSearch("");
                  }}
                  paginate
                  maxResults={PER_PAGE - 1}
                  isLoading={isLoading}
                  multiple={false}
                  allowNew={false}
                  options={businessEntityOptions}
                  labelKey={(option) => `${option.legalName}`}
                  minLength={0}
                  style={{ width: "200px" }}
                  onSearch={_handleSearch}
                  filterBy={_filterCallback}
                  onPaginate={_handlePagination}
                  placeholder={intl.formatMessage({
                    id: "SEARCH_BUSINESS_ENTITY",
                  })}
                  renderMenuItemChildren={(option, props) => (
                    <div key={option.id}>
                      {option.id == "noentity"
                        ? option.legalName
                        : option.legalName}
                    </div>
                  )}
                  paginationText={intl.formatMessage({
                    id: "SHOW_MORE_RESULTS",
                  })}
                  useCache={false}
                  ref = {businessEntityRef}
                />
                <Form.Text className="text-muted">
                  <b>{intl.formatMessage({ id: "FILTER" })}</b>{" "}
                  {intl.formatMessage({ id: "USER_BY_BUSINESS_ENTITY" })}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {!isLoading && userList.length == 0 && (
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={userImage}
                  alt="NoUser"
                  style={{ height: "200px", marginTop: "20px" }}
                />
                <h4>{intl.formatMessage({ id: "NO_USERS_TO_DISPLAY" })}</h4>
              </Col>
            </Row>
          )}
          {userList.length != 0 && (
            <Row>
              <Col>
                <div className={classes.tableWrapper}>
                  {isLoading && <LinearProgress />}
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <UserTableHead
                      order={filter.order}
                      orderBy={filter.orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={userList.length}
                    ></UserTableHead>
                    <TableBody>
                      {userList.map((row) => {
                        return (
                          <TableRow hover key={row.id} onClick={() => {
                            openUserDialog(row.id)}}>
                            <StyledTableCell align="left">
                              {row.firstName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.lastName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.email}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span
                                className={getUserIsActiveCssClasses(
                                  row.isActive
                                )}
                              >
                                {row.isActive
                                  ? intl.formatMessage({ id: "ACTIVE" })
                                  : intl.formatMessage({ id: "INACTIVE" })}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.userRole?.map((r) => (
                                <span key={r.role}
                                  className={getUserRoleCssClasses(r.role)}
                                  style={{ marginLeft: "3px" }}
                                >
                                  {cf.format("Roles", r.role, intl.locale)}
                                </span>
                              ))}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.businessEntity
                                ? row.businessEntity.legalName
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.registrationChannel}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Moment format="ll">{row.createdOn}</Moment>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                {!isLoading && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={filter.rowsPerPage}
                    page={filter.page}
                    backIconButtonProps={{
                      "aria-label": intl.formatMessage({ id: "PREVIOUS_PAGE" }),
                    }}
                    nextIconButtonProps={{
                      "aria-label": intl.formatMessage({ id: "NEXT_PAGE" }),
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                  }
                  label={intl.formatMessage({ id: "DENSE_PADDING" })}
                />
              </Col>
            </Row>
          )}
        </CardBody>
        <UserDialog show={showUserDialog} onHide={closeUserDialog} userId={currentUserId} />
      </Card>
    </>
  );
}
export default injectIntl(UserList);
