
const CODE_STORAGE =
{
    'FreightMode'
        : [
            { code: 'OCEAN', lang: 'en', text: 'Ocean' },
            { code: 'AIR', lang: 'en', text: "Air" },
            { code: 'BOTH', lang: 'en', text: "Both"},

            { code: 'OCEAN', lang: 'ko', text: '해상' },
            { code: 'AIR', lang: 'ko', text: "항공" },
            { code: 'BOTH', lang: 'ko', text: "모두 선택"},

            { code: 'OCEAN', lang: 'zh', text: '海运' },
            { code: 'AIR', lang: 'zh', text: "空运" },
        ],
    'FreightType'
        : [
            { code: 'FCL', lang: 'en', text: 'FCL' },
            { code: 'LCL', lang: 'en', text: "LCL" },

            { code: 'FCL', lang: 'ko', text: 'FCL' },
            { code: 'LCL', lang: 'ko', text: "LCL" },

            { code: 'FCL', lang: 'zh', text: '整箱货' },
            { code: 'LCL', lang: 'zh', text: "拼箱货" },
        ],
    'LocationType'
        : [
            { code: 'WAREHOUSE', lang: 'en', text: 'Warehouse' },
            { code: 'PORT', lang: 'en', text: 'Port' },
            { code: 'AIRPORT', lang: 'en', text: 'Airport' },
            { code: 'BUSINESS', lang: 'en', text: 'Business' },
            { code: 'RESIDENTIAL', lang: 'en', text: 'Residential' },
            { code: 'PNPLINE', lang: 'en', text: 'PNPLINE' },
            { code: 'OTHER', lang: 'en', text: 'Other' },

            { code: 'WAREHOUSE', lang: 'ko', text: '창고' },
            { code: 'PORT', lang: 'ko', text: '항구' },
            { code: 'AIRPORT', lang: 'ko', text: '공항' },
            { code: 'BUSINESS', lang: 'ko', text: '상업주소' },
            { code: 'RESIDENTIAL', lang: 'ko', text: '주거주소' },
            { code: 'PNPLINE', lang: 'en', text: 'PNPLINE' },
            { code: 'OTHER', lang: 'ko', text: '기타' },

            { code: 'WAREHOUSE', lang: 'zh', text: '仓库' },
            { code: 'PORT', lang: 'zh', text: '港口' },
            { code: 'AIRPORT', lang: 'zh', text: '机场' },
            { code: 'BUSINESS', lang: 'zh', text: '商务地址' },
            { code: 'RESIDENTIAL', lang: 'zh', text: '住宅' },
            { code: 'PNPLINE', lang: 'en', text: 'PNPLINE' },
            { code: 'OTHER', lang: 'zh', text: '其他' },
        ],
    'QuoteRequestStatus'
        : [
            { code: 'INSTANT_QUOTED', lang: 'en', text: 'Instant Quote' },
            { code: 'SUBMITTED', lang: 'en', text: 'Submitted' },
            { code: 'IN_REVIEW', lang: 'en', text: 'In review' },
            { code: 'QUOTED', lang: 'en', text: 'Quoted' },
            { code: 'ACCEPTED', lang: 'en', text: 'Accepted' },
            { code: 'REJECTED', lang: 'en', text: 'Rejected' },
            { code: 'CANCELLED', lang: 'en', text: 'Cancelled' },
            { code: 'ARCHIVED', lang: 'en', text: 'Archived' },
            { code: 'DELETED', lang: 'en', text: 'Deleted' },

            { code: 'INSTANT_QUOTED', lang: 'ko', text: '간편견적' },
            { code: 'SUBMITTED', lang: 'ko', text: '요청완료' },
            { code: 'IN_REVIEW', lang: 'ko', text: '견적 진행중' },
            { code: 'QUOTED', lang: 'ko', text: '견적 완료' },
            { code: 'ACCEPTED', lang: 'ko', text: '수락됨' },
            { code: 'REJECTED', lang: 'ko', text: '거절됨' },
            { code: 'CANCELLED', lang: 'ko', text: '취소됨' },
            { code: 'ARCHIVED', lang: 'ko', text: '보관됨' },
            { code: 'DELETED', lang: 'ko', text: '삭제됨' },

            { code: 'INSTANT_QUOTED', lang: 'zh', text: '即时报价' },
            { code: 'SUBMITTED', lang: 'zh', text: '已提交' },
            { code: 'IN_REVIEW', lang: 'zh', text: '审查中' },
            { code: 'QUOTED', lang: 'zh', text: '已报价' },
            { code: 'ACCEPTED', lang: 'zh', text: '已接受' },
            { code: 'REJECTED', lang: 'zh', text: '已拒绝' },
            { code: 'CANCELLED', lang: 'zh', text: '已取消' },
            { code: 'ARCHIVED', lang: 'zh', text: '已存档' },
            { code: 'DELETED', lang: 'zh', text: '已删除' }
        ],
        'QuoteStatus'
        : [
            { code: 'NEW', lang: 'en', text: 'New' },
            { code: 'INSTANT_QUOTED', lang: 'en', text: 'Instant' },
            { code: 'DRAFT', lang: 'en', text: 'Draft' },
            { code: 'HOLDED', lang: 'en', text: 'Holded' },
            { code: 'EXPIRED', lang: 'en', text: 'Expired' },
            { code: 'CONFIRMED', lang: 'en', text: 'Confirmed' },
            { code: 'ACCEPTED', lang: 'en', text: 'Accepted' },
            { code: 'REJECTED', lang: 'en', text: 'Rejected' },
            { code: 'NEW', lang: 'ko', text: '신규' },
            { code: 'INSTANT_QUOTED', lang: 'ko', text: '간편견적' },
            { code: 'DRAFT', lang: 'ko', text: '초안' },
            { code: 'HOLDED', lang: 'ko', text: '보류' },
            { code: 'EXPIRED', lang: 'ko', text: '만료' },
            { code: 'CONFIRMED', lang: 'ko', text: '확정' },
            { code: 'ACCEPTED', lang: 'en', text: '수락' },
            { code: 'REJECTED', lang: 'en', text: '거절' },
           
        ],
        'DocumentType'
        :[
            { code: 'ARRIVAL_NOTICE', lang: 'en', text: 'Arrival Notice' },
            //{ code: 'BOND_APPLICATION_FORM', lang: 'en', text: 'Bond Application Form' },
            { code: 'CERTIFICATE_OF_ORIGIN', lang: 'en', text: 'Certificate of Origin' },
            { code: 'CERTIFICATE_OF_BUSINESS_REGISTRATION', lang: 'en', text: 'Certifinate of Business Registration' },
            //{ code: 'CUSTOMS_INVOICE', lang: 'en', text: 'Customs Invoice' },
            //{ code: 'CUSTOMS_RELEASE', lang: 'en', text: 'Customs Release' },
            //{ code: 'DEBIT_NOTE', lang: 'en', text: 'Debit Note' },
            //{ code: 'INVOICE', lang: 'en', text: 'Invoice' },
            //{ code: 'EXPORT_PERMIT', lang: 'en', text: 'Export Permit' },
            { code: 'HOUSE_BILL_OF_LADDING', lang: 'en', text: 'House Bill of Ladding' },
            //{ code: 'IRS_SS_4', lang: 'en', text: 'IRS SS-4 Form' },
            { code: 'ISF_FILE', lang: 'en', text: 'ISF File' },
            { code: 'MASTER_BILL_OF_LADDING', lang: 'en', text: 'Master Bill of Ladding' },
            { code: 'COMMERCIAL_INVOICE_AND_PACKING_LIST', lang: 'en', text: 'Commercial Invoice & Packing List' },
            //{ code: 'PACKING_LIST', lang: 'en', text: 'Packing List' },
            { code: 'POWER_OF_ATTORNEY', lang: 'en', text: 'Power of Attorney' },
            //{ code: 'QUOTATION', lang: 'en', text: 'Quotation' },
            { code: 'DEBIT_CREDIT_NOTE', lang: 'en', text: 'Debit/Credit Note' },
            { code: 'MARINE_INSURANCE', lang: 'en', text: 'Marine Insurance' },
            { code: 'EXPORT_DECLARATION_CERTIFICATE', lang: 'en', text: 'Export Declaration Certificate' },
            { code: 'ENTRY_SUMMARY', lang: 'en', text: 'Entry Summary' },
            { code: 'FILE_3461', lang: 'en', text: '3461 File' },
            { code: 'ACE', lang: 'en', text: 'ACE' },
            { code: 'CEO_PASSPORT', lang: 'en', text: 'Copy of CEO Passport' },
            { code: 'OTHER', lang: 'en', text: 'Other' },
            { code: 'INVOICE_ATTACHMENT', lang: 'en', text: 'Invoice Attachment' },
            //{ code: 'COMMERCIAL_INVOICE', lang: 'en', text: 'Commercial Invoice' },
            { code: 'CONSOL_RATE', lang: 'en', text: 'Consol Rate' },

            { code: 'ARRIVAL_NOTICE', lang: 'ko', text: '화물도착 통지서' },
            //{ code: 'BOND_APPLICATION_FORM', lang: 'ko', text: '채권 지원서' },
            { code: 'CERTIFICATE_OF_ORIGIN', lang: 'ko', text: '원산지 증명서' },
            { code: 'CERTIFICATE_OF_BUSINESS_REGISTRATION', lang: 'ko', text: '사업자 등록증' },
            //{ code: 'CUSTOMS_INVOICE', lang: 'ko', text: '통관 송장' },
            //{ code: 'CUSTOMS_RELEASE', lang: 'ko', text: '통관 수취서' },
            //{ code: 'DEBIT_NOTE', lang: 'ko', text: '차변표' },
            //{ code: 'INVOICE', lang: 'ko', text: '송장' },
            //{ code: 'EXPORT_PERMIT', lang: 'ko', text: '수출허가서' },
            { code: 'HOUSE_BILL_OF_LADDING', lang: 'ko', text: '하우스 선하증권' },
            //{ code: 'IRS_SS_4', lang: 'ko', text: 'IRS SS-4 양식' },
            { code: 'ISF_FILE', lang: 'ko', text: 'ISF 파일' },
            { code: 'MASTER_BILL_OF_LADDING', lang: 'ko', text: '마스터 선하증권' },
            { code: 'COMMERCIAL_INVOICE_AND_PACKING_LIST', lang: 'ko', text: '상업 송장 & 포장명세서' },
            //{ code: 'PACKING_LIST', lang: 'ko', text: '포장명세서' },
            { code: 'POWER_OF_ATTORNEY', lang: 'ko', text: '위임장' },
            //{ code: 'QUOTATION', lang: 'ko', text: '견적서' },
            { code: 'DEBIT_CREDIT_NOTE', lang: 'ko', text: '크레딧/데빗 노트' },
            { code: 'MARINE_INSURANCE', lang: 'ko', text: '해상보험' },
            { code: 'EXPORT_DECLARATION_CERTIFICATE', lang: 'ko', text: '수출신고 필증' },
            { code: 'ENTRY_SUMMARY', lang: 'ko', text: '납세신고서류' },
            { code: 'FILE_3461', lang: 'ko', text: '3461 File' },
            { code: 'ACE', lang: 'ko', text: 'ACE' },
            { code: 'CEO_PASSPORT', lang: 'ko', text: '대표자 여권카피' },
            { code: 'OTHER', lang: 'ko', text: '기타' },
            { code: 'INVOICE_ATTACHMENT', lang: 'ko', text: '송장 첨부파일' },
            //{ code: 'COMMERCIAL_INVOICE', lang: 'ko', text: '상업 송장' },
            { code: 'CONSOL_RATE', lang: 'ko', text: '콘솔 요율표' },

            { code: 'ARRIVAL_NOTICE', lang: 'zh', text: '到货通知书' },
            //{ code: 'BOND_APPLICATION_FORM', lang: 'zh', text: '债券申请表' },
            { code: 'CERTIFICATE_OF_ORIGIN', lang: 'zh', text: '原产地证明' },
            { code: 'CERTIFICATE_OF_BUSINESS_REGISTRATION', lang: 'zh', text: '营业执照' },
            //{ code: 'CUSTOMS_INVOICE', lang: 'zh', text: '海关发票' },
            //{ code: 'CUSTOMS_RELEASE', lang: 'zh', text: '海关放行单' },
            //{ code: 'DEBIT_NOTE', lang: 'zh', text: '借项通知单' },
            //{ code: 'INVOICE', lang: 'zh', text: '发票' },
            //{ code: 'EXPORT_PERMIT', lang: 'zh', text: '出口许可证' },
            { code: 'HOUSE_BILL_OF_LADDING', lang: 'zh', text: '货代提单' },
            //{ code: 'IRS_SS_4', lang: 'zh', text: 'IRS SS-4 格式' },
            { code: 'ISF_FILE', lang: 'zh', text: 'ISF文件' },
            { code: 'MASTER_BILL_OF_LADDING', lang: 'zh', text: '主提单' },
            { code: 'COMMERCIAL_INVOICE_AND_PACKING_LIST', lang: 'zh', text: 'Commercial Invoice & Packing List' },
            //{ code: 'PACKING_LIST', lang: 'zh', text: '装箱单' },
            { code: 'POWER_OF_ATTORNEY', lang: 'zh', text: '授权书' },
            //{ code: 'QUOTATION', lang: 'zh', text: '报价单' },
            { code: 'DEBIT_CREDIT_NOTE', lang: 'zh', text: 'Debit/Credit Note' },
            { code: 'MARINE_INSURANCE', lang: 'zh', text: 'Marine Insurance' },
            { code: 'EXPORT_DECLARATION_CERTIFICATE', lang: 'zh', text: 'Export Declaration Certificate' },
            { code: 'ENTRY_SUMMARY', lang: 'zh', text: 'Entry Summary' },
            { code: 'FILE_3461', lang: 'zh', text: '3461 File' },
            { code: 'ACE', lang: 'zh', text: 'ACE' },
            { code: 'CEO_PASSPORT', lang: 'zh', text: 'Copy of CEO Passport' },
            { code: 'OTHER', lang: 'zh', text: '其他' },
            { code: 'INVOICE_ATTACHMENT', lang: 'zh', text: 'Invoice Attachment' },
            //{ code: 'COMMERCIAL_INVOICE', lang: 'zh', text: 'Commercial Invoice' },
            { code: 'CONSOL_RATE', lang: 'zh', text: 'Consol Rate' }
        ],
        'ShipmentStatus'
        :[
            { code: 'DRAFT', lang: 'en', text: 'Draft' },
            { code: 'AT_ORIGIN_LOCATION', lang: 'en', text: 'At Origin Location' },
            { code: 'ORIGIN_STOP_OFF', lang: 'en', text: 'Origin Stop off' },
            { code: 'CONSOLIDATION', lang: 'en', text: 'Consolidation' },
            { code: 'AWAITING_ARRIVAL_AT_DEPARTURE_PORT', lang: 'en', text: 'Awaiting Arrival at Departure Port' },
            { code: 'AT_DEPARTURE_PORT', lang: 'en', text: 'At Departure Port' },
            { code: 'IN_TRANSIT_TO_INTERMEDIARY_PORT', lang: 'en', text: 'In Transit to Intermediary Port' },
            { code: 'AT_INTERMEDIARY_PORT', lang: 'en', text: 'At Intermediary Port' },
            { code: 'IN_TRANSIT_TO_ARRIVAL_PORT', lang: 'en', text: 'In Transit to Arrival Port' },
            { code: 'AT_ARRIVAL_PORT', lang: 'en', text: 'At Arrival Port' },
            { code: 'DECONSOLIDATION', lang: 'en', text: 'Deconsolidation' },
            { code: 'DESTINATION_STOP_OFF', lang: 'en', text: 'Destination Stop off' },
            { code: 'IN_TRANSIT_TO_FINAL_DESTINATION', lang: 'en', text: 'In Transit To Final Destination' },
            { code: 'DELIVERED_IN_FULL', lang: 'en', text: 'Deliverd in Full' },

            { code: 'DRAFT', lang: 'ko', text: '초안' },
            { code: 'AT_ORIGIN_LOCATION', lang: 'ko', text: '출발 대기중' },
            { code: 'ORIGIN_STOP_OFF', lang: 'ko', text: '출발지 정차' },
            { code: 'CONSOLIDATION', lang: 'ko', text: '화물혼재' },
            { code: 'AWAITING_ARRIVAL_AT_DEPARTURE_PORT', lang: 'ko', text: '출발지 항구 도착대기중' },
            { code: 'AT_DEPARTURE_PORT', lang: 'ko', text: '출발지 항구도착' },
            { code: 'IN_TRANSIT_TO_INTERMEDIARY_PORT', lang: 'ko', text: '경유 항구 이동중' },
            { code: 'AT_INTERMEDIARY_PORT', lang: 'ko', text: '경유항구 도착' },
            { code: 'IN_TRANSIT_TO_ARRIVAL_PORT', lang: 'ko', text: '목적지 항구이동중' },
            { code: 'AT_ARRIVAL_PORT', lang: 'ko', text: '항구 도착' },
            { code: 'DECONSOLIDATION', lang: 'ko', text: '혼재화물 적하' },
            { code: 'DESTINATION_STOP_OFF', lang: 'ko', text: '목적지 정차' },
            { code: 'IN_TRANSIT_TO_FINAL_DESTINATION', lang: 'ko', text: '최종 목적지 이동중' },
            { code: 'DELIVERED_IN_FULL', lang: 'ko', text: '배송완료' },

            { code: 'DRAFT', lang: 'zh', text: '草稿' },
            { code: 'AT_ORIGIN_LOCATION', lang: 'zh', text: '在等待出发' },
            { code: 'ORIGIN_STOP_OFF', lang: 'zh', text: '出发地停车' },
            { code: 'CONSOLIDATION', lang: 'zh', text: '拼柜货' },
            { code: 'AWAITING_ARRIVAL_AT_DEPARTURE_PORT', lang: 'zh', text: '等待抵达出发港' },
            { code: 'AT_DEPARTURE_PORT', lang: 'zh', text: '抵达出发地港口' },
            { code: 'IN_TRANSIT_TO_INTERMEDIARY_PORT', lang: 'zh', text: '中转至中间港' },
            { code: 'AT_INTERMEDIARY_PORT', lang: 'zh', text: '抵达经由地港口' },
            { code: 'IN_TRANSIT_TO_ARRIVAL_PORT', lang: 'zh', text: '运输至到达港' },
            { code: 'AT_ARRIVAL_PORT', lang: 'zh', text: '到港' },
            { code: 'DECONSOLIDATION', lang: 'zh', text: '载拼柜货' },
            { code: 'DESTINATION_STOP_OFF', lang: 'zh', text: '目的地停车' },
            { code: 'IN_TRANSIT_TO_FINAL_DESTINATION', lang: 'zh', text: '运输至最终目的地' },
            { code: 'DELIVERED_IN_FULL', lang: 'zh', text: '送完货' },
        
        ],
        'ServiceType'
        :[
            { code: 'PORT_TO_PORT', lang: 'en', text: 'Port to port' },
            { code: 'PORT_TO_DOOR', lang: 'en', text: 'Port to door' },
            { code: 'DOOR_TO_PORT', lang: 'en', text: 'Door to port' },
            { code: 'DOOR_TO_DOOR', lang: 'en', text: 'Door to door' },

            { code: 'PORT_TO_PORT', lang: 'ko', text: '항구간 운송' },
            { code: 'PORT_TO_DOOR', lang: 'ko', text: '항구-목적지 운송' },
            { code: 'DOOR_TO_PORT', lang: 'ko', text: '출발지-항구 운송' },
            { code: 'DOOR_TO_DOOR', lang: 'ko', text: '출발지-목적지 운송' },

            { code: 'PORT_TO_PORT', lang: 'zh', text: '港到港' },
            { code: 'PORT_TO_DOOR', lang: 'zh', text: '港口到门口' },
            { code: 'DOOR_TO_PORT', lang: 'zh', text: '门口到起运港' },
            { code: 'DOOR_TO_DOOR', lang: 'zh', text: '门到门' },
        ],
        'RouteType'
        :[
            { code: 'TRUCKING', lang: 'en', text: 'Trucking' },
            { code: 'DRAYGE', lang: 'en', text: 'Drayge' },
            { code: 'FCL', lang: 'en', text: 'FCL' },
            { code: 'LCL', lang: 'en', text: 'LCL' },
            { code: 'AIR', lang: 'en', text: 'AIR' },
            { code: 'OTHER', lang: 'en', text: 'OTHER' },

            { code: 'TRUCKING', lang: 'ko', text: '트럭운송' },
            { code: 'DRAYGE', lang: 'ko', text: '내륙운송' },
            { code: 'FCL', lang: 'ko', text: 'FCL' },
            { code: 'LCL', lang: 'ko', text: 'LCL' },
            { code: 'AIR', lang: 'ko', text: '항공' },
            { code: 'OTHER', lang: 'ko', text: '기타' },

            { code: 'TRUCKING', lang: 'zh', text: '货车运输' },
            { code: 'DRAYGE', lang: 'zh', text: '内地运输' },
            { code: 'FCL', lang: 'zh', text: '整箱货' },
            { code: 'LCL', lang: 'zh', text: '拼箱货' },
            { code: 'AIR', lang: 'zh', text: '空运' },
            { code: 'OTHER', lang: 'zh', text: '其他' }
        ],
        'PackageType'
        :[
            { code: 'PALLET', lang: 'en', text: 'Pallet' },
            { code: 'CRATE', lang: 'en', text: 'Crate' },
            { code: 'BOX', lang: 'en', text: 'Box' },
            { code: 'OTHER', lang: 'en', text: 'Other' },

            { code: 'PALLET', lang: 'ko', text: '팔레트' },
            { code: 'CRATE', lang: 'ko', text: '크레이트' },
            { code: 'BOX', lang: 'ko', text: '상자' },
            { code: 'OTHER', lang: 'ko', text: '기타' },

            { code: 'PALLET', lang: 'zh', text: '卡板' },
            { code: 'CRATE', lang: 'zh', text: '木箱' },
            { code: 'BOX', lang: 'zh', text: '纸箱' },
            { code: 'OTHER', lang: 'zh', text: '其它' },
        ],
        'StakeholderType'
        :[
            { code: 'OWNER', lang: 'en', text: 'Owner' },
            { code: 'MANAGER', lang: 'en', text: 'Manager' },
            { code: 'DEVELOPER', lang: 'en', text: 'Developer' },
            { code: 'OPERATOR', lang: 'en', text: 'Operator' },
            { code: 'CUSTOMER', lang: 'en', text: 'Customer' },
            { code: 'OBSERVER', lang: 'en', text: 'Observer' },

            { code: 'OWNER', lang: 'ko', text: '화주' },
            { code: 'MANAGER', lang: 'ko', text: '담당자' },
            { code: 'DEVELOPER', lang: 'ko', text: '개발자' },
            { code: 'OPERATOR', lang: 'ko', text: '업무자' },
            { code: 'CUSTOMER', lang: 'ko', text: '고객' },
            { code: 'OBSERVER', lang: 'ko', text: '관찰자' },

            { code: 'OWNER', lang: 'zh', text: '船主' },
            { code: 'MANAGER', lang: 'zh', text: '负责人' },
            { code: 'DEVELOPER', lang: 'zh', text: '开发者' },
            { code: 'OPERATOR', lang: 'zh', text: '操作员' },
            { code: 'CUSTOMER', lang: 'zh', text: '客户' },
            { code: 'OBSERVER', lang: 'zh', text: '观察员' },
        ],
        'Roles'
        :[
            { code: 'MANAGER', lang: 'en', text: 'Manager' },
            { code: 'DEVELOPER', lang: 'en', text: 'Developer' },
            { code: 'OPERATOR', lang: 'en', text: 'Operator' },
            { code: 'CUSTOMER', lang: 'en', text: 'Customer' },
            { code: 'QUOTE_RECEIVER', lang: 'en', text: 'Quote Receiver' },

            { code: 'MANAGER', lang: 'ko', text: '관리자' },
            { code: 'DEVELOPER', lang: 'ko', text: '개발자' },
            { code: 'OPERATOR', lang: 'ko', text: '직원' },
            { code: 'CUSTOMER', lang: 'ko', text: '고객' },
            { code: 'QUOTE_RECEIVER', lang: 'ko', text: '견적수신자' },
            
            { code: 'MANAGER', lang: 'zh', text: '管理者' },
            { code: 'DEVELOPER', lang: 'zh', text: '开发者' },
            { code: 'OPERATOR', lang: 'zh', text: '员工' },
            { code: 'CUSTOMER', lang: 'zh', text: '客户' },
            { code: 'QUOTE_RECEIVER', lang: 'zh', text: '报价接收者' },
        ],
        'FeeCategory'
        :[
            { code: 'FREIGHT_CHARGE', lang: 'en', text: 'Freight Charge'},
            { code: 'ORIGIN_CHARGE', lang: 'en', text: 'Origin Charge'},
            { code: 'DESTINATION_CHARGE', lang: 'en', text: 'Destination Charge'},
            { code: 'CUSTOMS_CHARGE', lang: 'en', text: 'Customs Charge'},
            { code: 'ADDITIONAL_CHARGE', lang: 'en', text: 'Additional Charge'},
            { code: 'DISCOUNT', lang: 'en', text: 'Discount'},
            { code: 'OTHER', lang: 'en', text: 'Other'},

            { code: 'FREIGHT_CHARGE', lang: 'ko', text: '화물운임'},
            { code: 'ORIGIN_CHARGE', lang: 'ko', text: '출발지 비용'},
            { code: 'DESTINATION_CHARGE', lang: 'ko', text: '도착지 비용'},
            { code: 'CUSTOMS_CHARGE', lang: 'ko', text: '통관관련 비용'},
            { code: 'ADDITIONAL_CHARGE', lang: 'ko', text: '부가서비스'},
            { code: 'DISCOUNT', lang: 'ko', text: '할인'},
            { code: 'OTHER', lang: 'ko', text: '기타'},
            { code: 'FREIGHT_CHARGE', lang: 'en', text: 'Freight Charge'},
            { code: 'ORIGIN_CHARGE', lang: 'en', text: 'Origin Charge'},
            { code: 'DESTINATION_CHARGE', lang: 'en', text: 'Destination Charge'},
            { code: 'CUSTOMS_CHARGE', lang: 'en', text: 'Customs Charge'},
            { code: 'ADDITIONAL_CHARGE', lang: 'en', text: 'Additional Charge'},
            { code: 'DISCOUNT', lang: 'en', text: 'Discount'},
            { code: 'OTHER', lang: 'en', text: 'Other'},
        ],
        'AvailableMode'
        :[
            { code: 'OCEAN', lang: 'en', text: 'Ocean'},
            { code: 'AIR', lang: 'en', text: 'Air'},
            { code: 'BOTH', lang: 'en', text: 'Both'},
            { code: 'OCEAN', lang: 'ko', text: '해상'},
            { code: 'AIR', lang: 'ko', text: '항공'},
            { code: 'BOTH', lang: 'ko', text: '모두'},
            { code: 'OCEAN', lang: 'zh', text: '海运' },
            { code: 'AIR', lang: 'zh', text: "空运" },
            { code: 'BOTH', lang: 'zh', text: '都'}
        ],
        'InvoiceStatus'
        :[
            { code: 'NEW', lang: 'en', text: 'New'},
            { code: 'DRAFT', lang: 'en', text: 'Draft'},
            { code: 'OUTSTANDING', lang: 'en', text: 'Outstanding'},
            { code: 'PAID', lang: 'en', text: 'Paid'},
            { code: 'VOID', lang: 'en', text: 'Void'},
            { code: 'DELETED', lang: 'en', text: 'Deleted'},
            { code: 'PAST_DUE', lang: 'en', text: 'Past due'},
            { code: 'NEW', lang: 'ko', text: '신규'},
            { code: 'DRAFT', lang: 'ko', text: '초안'},
            { code: 'OUTSTANDING', lang: 'ko', text: '미납'},
            { code: 'PAID', lang: 'ko', text: '납부완료'},
            { code: 'VOID', lang: 'ko', text: '무효'},
            { code: 'DELETED', lang: 'ko', text: '삭제됨'},
            { code: 'PAST_DUE', lang: 'ko', text: '연체'},
            { code: 'NEW', lang: 'zh', text: 'New'},
            { code: 'DRAFT', lang: 'zh', text: 'Draft'},
            { code: 'OUTSTANDING', lang: 'zh', text: 'Outstanding'},
            { code: 'PAID', lang: 'zh', text: 'Paid'},
            { code: 'VOID', lang: 'zh', text: 'Void'},
            { code: 'DELETED', lang: 'zh', text: 'Deleted'},
            { code: 'PAST_DUE', lang: 'zh', text: 'Past due'},
        ],
        'InvoiceEntryType'
        :[
            { code: 'ADJUSTMENT', lang: 'en', text: 'Adjustment'},
            { code: 'CREDIT', lang: 'en', text: 'Credit'},
            { code: 'DISCOUNT', lang: 'en', text: 'Discount'},
            { code: 'ATTACHMENT', lang: 'en', text: 'Attachment'},
            { code: 'OTHER', lang: 'en', text: 'Other'},
            { code: 'ADJUSTMENT', lang: 'ko', text: '조정'},
            { code: 'CREDIT', lang: 'ko', text: '크레딧'},
            { code: 'DISCOUNT', lang: 'ko', text: '할인'},
            { code: 'ATTACHMENT', lang: 'en', text: '첨부항목'},
            { code: 'OTHER', lang: 'ko', text: '기타'},
            { code: 'ADJUSTMENT', lang: 'zh', text: 'Adjustment'},
            { code: 'CREDIT', lang: 'zh', text: 'Credit'},
            { code: 'DISCOUNT', lang: 'zh', text: 'Discount'},
            { code: 'ATTACHMENT', lang: 'en', text: 'Attachment'},
            { code: 'OTHER', lang: 'zh', text: 'Other'},
        ],
        'PaymentStatus'
        :[
            { code: 'DRAFT', lang: 'en', text: 'Draft'},
            { code: 'UNPAID', lang: 'en', text: 'Unpaid'},
            { code: 'PAID', lang: 'en', text: 'Paid'},
            { code: 'FAILED', lang: 'en', text: 'Failed'},
            { code: 'CANCELLED', lang: 'en', text: 'Cancelled'},
            { code: 'DRAFT', lang: 'ko', text: '초안'},
            { code: 'UNPAID', lang: 'ko', text: '미결제'},
            { code: 'PAID', lang: 'ko', text: '결제완료'},
            { code: 'FAILED', lang: 'ko', text: '결제실패'},
            { code: 'CANCELLED', lang: 'ko', text: '취소됨'},
            { code: 'DRAFT', lang: 'zh', text: 'Draft'},
            { code: 'UNPAID', lang: 'zh', text: 'Unpaid'},
            { code: 'PAID', lang: 'zh', text: 'Paid'},
            { code: 'FAILED', lang: 'zh', text: 'Failed'},
            { code: 'CANCELLED', lang: 'zh', text: 'Cancelled'},
        ],
        'PaymentMethodType'
        :[
            { code: 'UNKNOWN', lang: 'en', text: 'Unknown'},
            { code: 'CARD', lang: 'en', text: 'Card'},
            { code: 'ACH', lang: 'en', text: 'ACH'},
            { code: 'MANUAL', lang: 'en', text: 'Manual'},
            { code: 'UNKNOWN', lang: 'ko', text: '미확인'},
            { code: 'CARD', lang: 'ko', text: '카드'},
            { code: 'ACH', lang: 'ko', text: '송금'},
            { code: 'MANUAL', lang: 'ko', text: '수기확인'},
            { code: 'UNKNOWN', lang: 'zh', text: 'Unknown'},
            { code: 'CARD', lang: 'zh', text: 'Card'},
            { code: 'ACH', lang: 'zh', text: 'ACH'},
            { code: 'MANUAL', lang: 'zh', text: 'Manual'},
        ],
        'PaymentType'
        :[
            { code: 'UNKNONW', lang: 'en', text: 'Unknown'},
            { code: 'STRIPE', lang: 'en', text: 'Stripe'},
            { code: 'BANK_TRANSFER', lang: 'en', text: 'Bank Transfer'},
            { code: 'PAYPAL', lang: 'en', text: 'Paypal'},
            { code: 'VENMO', lang: 'en', text: 'Venmo'},
            { code: 'UNKNONW', lang: 'ko', text: '미확인'},
            { code: 'STRIPE', lang: 'ko', text: '스트라이프'},
            { code: 'BANK_TRANSFER', lang: 'ko', text: '은행송금'},
            { code: 'PAYPAL', lang: 'ko', text: '페이팔'},
            { code: 'VENMO', lang: 'ko', text: '벤모'},
            { code: 'UNKNONW', lang: 'zh', text: 'Bank Transfer'},
            { code: 'STRIPE', lang: 'zh', text: 'Stripe'},
            { code: 'BANK_TRANSFER', lang: 'zh', text: 'Discount'},
            { code: 'PAYPAL', lang: 'zh', text: 'Paypal'},
            { code: 'VENMO', lang: 'zh', text: 'Venmo'},
        ],
        'ConsolScheduleStatus'
        :[
            { code: 'DRAFT', lang: 'en', text: 'Draft'},
            { code: 'CONFIRMED', lang: 'en', text: 'Confirmed'},
            { code: 'CANCELLED', lang: 'en', text: 'Cancelled'},
            { code: 'DELETED', lang: 'en', text: 'Deleted'},
            { code: 'DRAFT', lang: 'ko', text: '초안'},
            { code: 'CONFIRMED', lang: 'ko', text: '확정됨'},
            { code: 'CANCELLED', lang: 'ko', text: '취소됨'},
            { code: 'DELETED', lang: 'ko', text: '삭제됨'}
        ],
        'ConsolStatus'
        :[
            { code: 'DRAFT', lang: 'en', text: 'Draft'},
            { code: 'SUBMITTED', lang: 'en', text: 'Submitted'},
            { code: 'CONFIRMED', lang: 'en', text: 'Confirmed'},
            { code: 'RECEIVED', lang: 'en', text: 'Received'},
            { code: 'AWAITING_SHIPMENT', lang: 'en', text: 'Awaiting Shipment'},
            { code: 'SHIPPED', lang: 'en', text: 'Shipped'},
            { code: 'DELIVERED', lang: 'en', text: 'Delivered'},
            { code: 'CANCELLED', lang: 'en', text: 'Cancelled'},
            { code: 'DELETED', lang: 'en', text: 'Deleted'},
            { code: 'DRAFT', lang: 'ko', text: '초안'},
            { code: 'SUBMITTED', lang: 'ko', text: '신청됨'},
            { code: 'CONFIRMED', lang: 'ko', text: '확인됨'},
            { code: 'RECEIVED', lang: 'ko', text: '인수됨'},
            { code: 'AWAITING_SHIPMENT', lang: 'ko', text: '배송준비완료'},
            { code: 'SHIPPED', lang: 'ko', text: '운송중'},
            { code: 'DELIVERED', lang: 'ko', text: '도착완료'},
            { code: 'CANCELLED', lang: 'ko', text: '취소됨'},
            { code: 'DELETED', lang: 'ko', text: '삭제됨'}
        ],
        'VerificationStatus'
        :[
            { code: 'NEED_VERIFICATION', lang: 'en', text: 'Need Verification'},
            { code: 'VERIFIED', lang: 'en', text: 'Verified'},
            { code: 'VERIFICATION_FAILED', lang: 'en', text: 'Verification Failed'},
            { code: 'NEED_VERIFICATION', lang: 'ko', text: '미검증'},
            { code: 'VERIFIED', lang: 'ko', text: '검증됨'},
            { code: 'VERIFICATION_FAILED', lang: 'ko', text: '검증 실패'}
        ],
        'BusinessType'
        :[
            { code: 'MANUFACTURING', lang: 'en', text: 'Manufacturing'},
            { code: 'DISTRIBUTOR', lang: 'en', text: 'Distributor'},
            { code: 'ECOMMERCE', lang: 'en', text: 'E-Commerce'},
            { code: 'LOGISTICS', lang: 'en', text: 'Logistics'},
            { code: 'ETC', lang: 'en', text: 'Etc'},
            { code: 'UNKNOWN', lang: 'en', text: 'Unknown'},
            { code: 'MANUFACTURING', lang: 'ko', text: '제조사/브랜드사'},
            { code: 'DISTRIBUTOR', lang: 'ko', text: '도매/소매 유통사'},
            { code: 'ECOMMERCE', lang: 'ko', text: '이커머스 판매/운영 대행사'},
            { code: 'LOGISTICS', lang: 'ko', text: '물류사'},
            { code: 'ETC', lang: 'ko', text: '그외'},
            { code: 'UNKNOWN', lang: 'ko', text: 'Unknown'},
        ],
        'FBAShipmentType'
        :[
            { code: 'PNPLINE', lang: 'en', text: 'PNPLINE'},
            { code: 'AMAZON_SPD', lang: 'en', text: 'Amazon SPD'},
            { code: 'AMAZON_LTL', lang: 'en', text: 'Amazon LTL'},
            { code: 'AMAZON_FTL', lang: 'en', text: 'Amazon FTL'},
            { code: 'UNKNOWN', lang: 'en', text: 'Undecided/unknown'},
            { code: 'PNPLINE', lang: 'ko', text: 'PNPLINE'},
            { code: 'AMAZON_SPD', lang: 'ko', text: '아마존 SPD'},
            { code: 'AMAZON_LTL', lang: 'ko', text: '아마존 LTL'},
            { code: 'AMAZON_FTL', lang: 'ko', text: '아마존 FTL'},
            { code: 'UNKNOWN', lang: 'ko', text: '미정/불확실'},
        ],
        'CurrencyCode'
        :[ 
            { code: 'USD', lang : 'en', text :"$"},
            { code: 'KRW', lang : 'en', text :"₩"},
            { code: 'CNY', lang : 'en', text :"RMB"},
            { code: 'USD', lang : 'ko', text :"$"},
            { code: 'KRW', lang : 'ko', text :"₩"},
            { code: 'CNY', lang : 'ko', text :"RMB"},
            { code: 'USD', lang : 'zh', text :"$"},
            { code: 'KRW', lang : 'zh', text :"₩"},
            { code: 'CNY', lang : 'zh', text :"元"},

        ]
        ,
        'InvoiceInputType'
        :[ 
            { code: 'FEE_ENTRY', lang : 'en', text :"Fee Entry"},
            { code: 'ATTACHMENT', lang : 'en', text :"Attachment"},
            { code: 'FEE_ENTRY', lang : 'ko', text :"요율항목"},
            { code: 'ATTACHMENT', lang : 'ko', text :"첨부"},
            { code: 'FEE_ENTRY', lang : 'zh', text :"Fee Entry"},
            { code: 'ATTACHMENT', lang : 'zh', text :"Attachment"}

        ]
};


export const PAYMENT_METHOD_TYPE =
{
    UNKNOWN : "UNKNOWN",
    CARD : "CARD",
    ACH : "ACH",
    MANUAL : "MANUAL"
}

export const PAYMENT_TYPE =
{
    UNKNONW : "UNKNONW",
    STRIPE : "STRIPE",
    BANK_TRANSFER : "BANK_TRANSFER",
    PAYPAL : "PAYPAL",
    VENMO : "VENMO"
}

const cf = {
    format: function (key,code,lang) {
        lang = lang || 'en';
        if (key) {
            try {
                return CODE_STORAGE[key].find(x => x.code === String(code) && x.lang === lang).text;
            } catch (e) {
                return code;
            }
        };
        var formattedObj = Object.assign({}, code);
        Object.keys(formattedObj).forEach(key => {
            var codes = CODE_STORAGE[key];
            if (codes) {
                try {
                    formattedObj[key] = CODE_STORAGE[key].find(x => x.code === String(formattedObj[key]) && x.lang === lang).text;
                } catch (e) { }
            }
        });
        return formattedObj;
    },
    getCodes: function (key) {
        return CODE_STORAGE[key];
    }
}



export default cf
